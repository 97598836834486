<template>
  <v-dialog
    v-model="isOpen"
    class="HawserConnectionDialog"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :max-width="466"
    :persistent="actionInProgress"
  >
    <v-sheet
      class="HawserConnectionDialog__card"
      color="white"
    >
      <h1
        class="UserDialog__title display-3 mb-4"
        v-text="$t('connection.ConnectToHive')"
      />

      <AppTextField
        v-model="connectionName"
        filled
        type="text"
        name="connection-name"
        autocomplete="organization"
        :label="$t('connection.ConnectionNameOptional')"
        :placeholder="project ? $t('connection.ConnectionName',{ projectName: project.name }) : ''"
        maxlength="128"
      />

      <v-radio-group
        v-model="connectMode"
        class="pt-0"
        hide-details
      >
        <v-radio
          :key="CONNECT_MODE.new"
          :value="CONNECT_MODE.new"
          :label="$t('connection.NewConnection')"
        />
        <v-radio
          :key="CONNECT_MODE.linkWithExisting"
          :value="CONNECT_MODE.linkWithExisting"
          :label="$t('connection.LinkExisting')"
        />
      </v-radio-group>

      <div class="HawserConnectionDialog__footer">
        <v-btn
          v-if="!projectHasConnection"
          v-show="connectMode === CONNECT_MODE.linkWithExisting"
          ref="jestLinkWithExistingBtn"
          :disabled="actionInProgress"
          :loading="actionInProgress"
          color="primary"
          depressed
          @click="$refs.fileInput.click()"
        >
          {{ $t('connection.Connect') }}
        </v-btn>
        <v-btn
          v-if="!projectHasConnection"
          v-show="connectMode === CONNECT_MODE.new"
          ref="jestGenerateNewBtn"
          :disabled="actionInProgress"
          :loading="actionInProgress"
          color="primary"
          depressed
          @click="generateNewConnection"
        >
          {{ $t('connection.Connect') }}
        </v-btn>
        <v-btn
          ref="jestCancelBtn"
          :disabled="actionInProgress"
          outlined
          color="primary"
          class="ml-2"
          @click="isOpen = false"
        >
          {{ $t('connection.Cancel') }}
        </v-btn>
      </div>

      <input
        ref="fileInput"
        type="file"
        accept=".yml,.yaml,.zip"
        style="position: fixed; top: 0; left: 0; transform: translate(-100%, -100%)"
        @change="importConnectionConfigFile = $event.target.files[0]"
      >
    </v-sheet>
  </v-dialog>
</template>

<script>
import Project from '@/store/orm/project'

const CONNECT_MODE = Object.freeze({
  new: 'new',
  linkWithExisting: 'link',
})

export default {
  name: 'HawserConnectionDialog',

  props: {
    dialogInstance: { type: Object, required: true },
    projectId: { type: String, required: true },
  },

  data() {
    return {
      CONNECT_MODE,

      actionInProgress: false,
      importConnectionConfigFile: null,
      connectionName: '',
      connectMode: CONNECT_MODE.new,
    }
  },

  computed: {
    isOpen: {
      get() { return this.dialogInstance.isOpen },
      set(isOpen) { this.dialogInstance.onDialogModelInput(isOpen) },
    },

    project() { return Project.find(this.projectId) },

    projectHasConnection() {
      const { project } = this
      return project && !!project.hawserID
    },
  },

  watch: {
    projectId: 'reset',

    importConnectionConfigFile(file) {
      if (file) this.linkToExistingConnection()
    },
  },

  methods: {
    reset() {
      this.actionInProgress = false
      this.importConnectionConfigFile = null
      this.connectionName = ''
      this.connectMode = CONNECT_MODE.new
    },

    action(promise) {
      if (this.actionInProgress) {
        return Promise.reject(new Error(this.$t('connection.alreadyInProgress')))
      }
      this.actionInProgress = true
      return Promise.resolve(promise)
        .finally(() => { this.actionInProgress = false })
    },

    linkToExistingConnection() {
      const {
        $store,
        projectId,
        connectionName,
        importConnectionConfigFile: configFile,
        action,
      } = this

      const linked = $store.dispatch(
        'projectConnections/linkToExistingConnection',
        { projectId, connectionName, configFile },
      )
        .then(() => $store.commit('$snackbar/setMessage', {
          message: this.$t('connection.ExistingConnectionEstablished'),
          persistent: true,
        }))
        .finally(() => {
          this.importConnectionConfigFile = null
          if (this.$refs.fileInput) this.$refs.fileInput.value = ''
        })
      return action(linked)
        .then(() => { this.isOpen = false })
    },

    generateNewConnection() {
      const { $store, projectId, connectionName, action } = this

      const downloaded = $store.dispatch(
        'projectConnections/createNewConnection',
        { projectId, connectionName },
      )
        .then(({ configUrl, supposedFilename, reloadProject }) => {
          const a = document.createElement('a')
          a.href = configUrl
          a.download = supposedFilename
          a.click()
          return reloadProject()
        })
        .then(() => $store.commit('$snackbar/setMessage', {
          message: this.$t('connection.NewConnectionCreated'),
          persistent: true,
        }))
      return action(downloaded)
        .then(() => { this.isOpen = false })
    },
  },
}
</script>

<style lang="sass">
.HawserConnectionDialog
  &__card
    padding: 48px 48px 32px

  &__footer
    margin-top: 39px
    margin-right: -16px
    display: flex
    justify-content: flex-end
</style>
